/* Login.css */

.login {
  background-color: #000; /* Черный фон */
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Исправил значение justify-content */
  color: white; /* Белый цвет текста */
}

.login__logo {
  margin-top: 20px;
  object-fit: contain;
  width: 150px;
}

.login__container {
  width: 80%; /* Изменил ширину контейнера на 80% ширины родительского блока */
  max-width: 300px; /* Добавил максимальную ширину контейнера для адаптивности */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto; /* Добавил этот стиль, чтобы центрировать форму по горизонтали */
  margin-top: 2%; /* Уменьшил верхний отступ для поднятия формы вверх страницы */

  border-radius: 10px;
  border: 1px solid white;
  padding: 30px;
  background-color: #000;
}

.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login__container > form > h5 {
  margin-bottom: 5px;
  font-size: 18px;
}

.login__container > form > input {
  height: 40px;
  margin-bottom: 15px;
  background-color: #333;
  border: 1px solid #222;
  border-radius: 5px;
  padding: 8px;
  color: white;
  width: 100%;
}

.login__signInButton {
  font-family: Calibri;
  font-size: 18px;
  background: #edbf61; /* Белый цвет кнопки */
  border-radius: 5px;
  width: 100%;
  height: 40px;
  border: none;
  margin-top: 15px;
  cursor: pointer;
  color: black; /* Черный цвет текста на кнопке */
  font-weight: bold;
  transition: background 0.3s ease;
}

.login__signInButton:hover {
  background: #e4b12e; /* Цвет кнопки при наведении курсора */
}

.login__container > p {
  margin-top: 10px;
  font-size: 11px;
  text-align: center;
}

.login__registerButton,
a.login__registerButton {
  font-family: Calibri;
  background: #fc4e04;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: none;
  margin-top: 15px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  transition: background 0.3s ease;
}

.login__registerButton:hover {
  background: #ff2a04;
  color: white;
}
@media only screen and (max-width: 600px) {
  .login__container > form > input {
    font-size: 16px;
  }
  .login__logo {
    margin-top: 20px;
    object-fit: contain;
    width: 130px;
  }
}
