.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 100px;
  padding: 20px;
  background-color: black;
  border: 1px solid #ffffff;
  border-radius: 3px;
}
.subtotal__text {
  text-align: center;
  color: aliceblue;
}
.subtotal > button {
  background: rgb(255, 106, 0);
  border-radius: 2px;
  width: 100%;
  height: 30px;
  /* border: 1px solid; */
  margin-top: 10px;
  border-color: #ffffff;
  color: black;
}
