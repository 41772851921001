/* Основные стили шапки */
.header {
  height: 80px;
  display: flex;
  align-items: center;
  background-color: black;
  position: sticky;
  top: 0;
  z-index: 200;
  justify-content: space-between;
  padding: 0 5px;
}

/* Логотип */
.header_logo {
  object-fit: contain;
  margin-top: 4px;
  margin-left: 1.5vw;
  width: 100px;
}

/* Область поиска */
.header__search {
  display: flex;
  align-items: center;
  border-radius: 20px;
  flex-grow: 1;
  margin-right: 10px;
  margin-left: 20px;
  position: relative;
}

.header__searchInput {
  padding: 3px;
  z-index: 10;
  display: none;
}

@media (min-width: 601px) {
  .header__searchInput {
    flex-grow: 1;
    display: block;
    margin-left: 2vw;
  }
}

.header__searchIcon {
  padding: 5px;
  background-color: aliceblue;
  cursor: pointer;
  z-index: 11;
}

/* Меню пользователя и корзина */
.header__menu {
  display: flex;
  justify-content: center;
  margin-left: 20px;
}

/* Десктопное меню */
.header__menuItem {
  position: relative;
  display: inline-block;
}

.header__option {
  color: aliceblue;
  margin: 0 20px;
  text-align: center;
  cursor: pointer;
}

.header__option:hover,
.header__optionLineTwo:hover {
  text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
}

.header__option:active,
.header__optionLineTwo:active {
  position: relative;
  top: 1px;
  text-shadow: none;
}

.header__option.active {
  text-decoration: underline;
}

/* Подменю десктопа */
.header__submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #222; /* Фон подменю */
  min-width: 150px;
  z-index: 300;
  flex-direction: column;
}

.header__menuItem:hover .header__submenu {
  display: flex;
}

/* Пункты простого подменю */
.header__submenuItem {
  color: #f0f0f0;
  padding: 10px 15px;
  text-align: left;
  white-space: nowrap;
  transition: background-color 0.2s ease;
}

.header__submenuItem:hover {
  background-color: #444;
}

/* Если подменю разделено на группы */
.header__submenuGroup {
  display: flex;
  flex-direction: column;
}

.header__submenuGroupHeader {
  color: #ddd;
  font-weight: bold;
  padding: 10px 15px;
  text-align: left;
  background-color: #222; /* тот же фон, можно изменить */
}

/* Блок логина */
.header__login {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: aliceblue;
  margin-left: 10px;
  white-space: nowrap;
}

.header__login span {
  display: block;
}

.header__optionLineOne {
  font-size: 10px;
}

.header__optionLineTwo {
  font-size: 18px;
  font-weight: 800;
}

/* Корзина */
.header__optionBasket {
  display: flex;
  align-items: center;
  color: aliceblue;
  margin-left: 15px;
}

.header__optionBasket:hover {
  box-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
  border-radius: 15px;
}

.header__optionBasket:active {
  position: relative;
  top: 1px;
  box-shadow: none;
}

.header__basketCount {
  margin-left: 1px;
  margin-right: 20px;
}

/* Иконка гамбургера */
.header__menuIcon {
  display: none;
}

/* Мобильная навигация */
.header__mobileNav {
  display: none;
  position: absolute;
  background-color: rgb(0, 0, 0);
  top: 80px;
  width: 70%;
  margin-left: 10%;
  margin-right: 10%;
  color: aliceblue;
  z-index: 250;
}

/* Мобильная версия */
@media only screen and (max-width: 600px) {
  .header {
    justify-content: flex-start;
    padding: 1vw;
  }

  .mobile-hidden {
    display: none;
  }

  .header__search.active .header__searchInput {
    display: block;
    width: 53vw;
    height: 23px;
    position: absolute;
    top: 0;
    left: 26px;
    font-size: 16px;
  }

  .header__searchIcon {
    z-index: 11;
  }

  .header__menuIcon {
    order: -2;
    display: block;
    color: white;
    cursor: pointer;
    margin-left: 5vw;
  }

  .header__search {
    order: -1;
    flex-grow: 100;
  }

  .header_logo {
    order: 3;
    width: 90px;
    margin-left: 9.7vw;
    margin-right: 4.3vw;
  }

  .header__login .header__optionLineOne,
  .header__login .header__optionLineTwo {
    font-size: 10px;
  }

  .header__menu {
    display: none;
  }

  .header__optionBasket {
    margin-right: 1.2vw;
  }

  .header__mobileNav {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .header__mobileMenuItem {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }

  .header__mobileNav .header__option {
    color: rgb(255, 255, 255);
    padding: 10px 20px;
    border: none;
    width: 100%;
    box-sizing: border-box;
    text-align: left;
  }

  /* Подменю мобильного меню – фон отличается от основного */
  .header__submenu.mobile {
    display: flex;
    position: static;
    flex-direction: column;
    padding-left: 20px;
    border: none;
    width: 100%;
    box-sizing: border-box;
    background-color: #222;
  }

  /* Если подменю мобильного меню разделено на группы */
  .header__submenuGroup {
    display: flex;
    flex-direction: column;
  }

  .header__submenuGroupHeader {
    color: #ddd;
    font-weight: bold;
    padding: 10px 20px;
    text-align: left;
    background-color: transparent;
  }

  .header__submenu.mobile .header__option {
    color: #f0f0f0;
    padding: 10px 20px;
    text-align: left;
    border: none;
    transition: background-color 0.2s ease;
  }

  .header__submenu.mobile .header__option:hover {
    background-color: #444;
  }

  .mobile-menu-icon {
    margin-left: 5px;
    vertical-align: middle;
  }

  .header__option,
  .header__login,
  .header__search {
    flex-shrink: 1;
  }

  .header__optionLineOne {
    max-width: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

/* Для очень маленьких экранов */
@media only screen and (max-width: 400px) {
  .header_logo {
    width: 70px;
  }
}

/* Для устройств с вырезом (notch) */
@supports (padding-top: env(safe-area-inset-top)) {
  .header {
    padding-top: env(safe-area-inset-top);
  }
  .header__mobileNav {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
