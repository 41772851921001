.payments {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: aliceblue;
  background-color: black; /* Черный фон */
}

.payments h2 {
  text-align: center;
  margin-bottom: 20px;
}

.payments .error-message,
.payments .success-message {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  color: white;
}

.payments .error-message {
  background-color: #f44336;
}

.payments .success-message {
  background-color: #4caf50;
}

.payments .input-group {
  margin-bottom: 15px;
}

.payments .input-group label {
  display: block;
  margin-bottom: 5px;
}

.payments .input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #333; /* Фон поля ввода */
  color: white; /* Цвет текста */
}

.payments button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.payments button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.payment__section {
  display: flex;
  padding: 20px;
  margin: 0 20px;
  margin-bottom: 20px;
  background-color: #333; /* Цвет фона */
  color: #ccc;
}

.payment__title {
  flex: 0.2;
}

.payment__address,
.payment__items,
.payment__details {
  flex: 0.8;
}

.payment__details > form {
  max-width: 400px;
  color: white;
}

.payment__details > h3 {
  padding-bottom: 20px;
}

.payment__details > form > div > button {
  background: #f0c14b;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  font-weight: bolder;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.payment__details .StripeElement {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #ffffff; /* Цвет фона поля ввода */
  color: white; /* Белый цвет текста */
}

@media (max-width: 600px) {
  .payments {
    margin: 20px;
  }
  .payments .input-group input {
    font-size: 18px;
  }

  .payment__details .StripeElement,
  .payment__details .StripeElement .__PrivateStripeElement,
  .payment__details .StripeElement .__PrivateStripeElement iframe {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
}
