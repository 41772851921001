/* Глобальные стили */
* {
  margin: 0;
  padding: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Домашняя страница стили */
.home {
  overflow: hidden; /* Убираем любую возможность прокрутки в этом контейнере */
  background-color: black;
}

.home__container {
  background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 20%,
      rgba(255, 255, 255, 0) 50%
    ),
    url("../public/images/home_back.jpeg");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  position: relative;
}

.home__row {
  display: flex;
  justify-content: center;
  margin: 10px 50px;
  padding: 20px;
}

.home__row2 {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .home {
    min-width: 0; /* Удаляем min-width, чтобы избежать горизонтальной прокрутки */
  }
  .home__container {
    background-image: none; /* Отключает фоновое изображение для экранов шириной < 600px */
  }

  .home__row,
  .home__row2 {
    flex-direction: column;
    margin: 10px; /* Уменьшаем марджин для меньших экранов */
    padding: 10px; /* Уменьшаем паддинг для меньших экранов */
  }
}
