.checkoutProduct {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd; /* Добавляем линию между элементами */
  padding-bottom: 20px; /* Добавляем отступ снизу для лучшего визуального разделения */
}

.checkoutProduct:last-child {
  border-bottom: none; /* Убираем линию у последнего элемента, чтобы не было лишнего разделителя */
}

.checkoutProduct__info {
  flex: 1;
  padding-left: 20px;
  color: aliceblue;
}

.checkoutProduct__info > button {
  display: inline-block;
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: black;
  padding: 5px 10px;
}

.checkoutProduct__image {
  object-fit: contain;
  width: 100px;
  height: 100px;
  margin-right: 20px;
}

.checkoutProduct__rating {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
  margin-bottom: 5px;
}

.checkoutProduct__text {
  font-size: 15px;
  color: aliceblue;
  margin-bottom: 10px;
}
