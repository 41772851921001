/* Стили продуктов */
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  padding: 10px;
  width: 100%;
  /* Удаляем min-width, чтобы избежать горизонтальной прокрутки на мобильных устройствах */
  background-color: rgba(255, 255, 255, 0.6); /* Белый с 60% прозрачностью */
  z-index: 1;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: orange;
  border-radius: 2px;
  width: 30%;
  height: 30px;
  border-color: #111;
  margin-top: 10px;
}

.product__info {
  height: 100px;
  margin: 15px;
  align-items: center;
}

.product__price {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.product__rating {
  display: flex;
  justify-content: right;
  align-items: right;
}

@media only screen and (max-width: 600px) {
  .product {
    width: auto; /* Полная ширина */
    margin: 10px 5px; /* Уменьшенные отступы сбоку */
  }

  .product > img {
    max-height: none; /* Убираем ограничение максимальной высоты */
  }

  .product > button {
    width: 80%; /* Увеличиваем ширину кнопки */
    margin-top: 5px; /* Уменьшаем верхний отступ */
    color: black;
  }

  .product__info {
    height: auto; /* Высота автоматическая, чтобы подстраиваться под контент */
  }

  .product__price,
  .product__rating {
    justify-content: center; /* Центрирование по содержимому */
  }
}
