/* Существующие стили вашей страницы оформления заказа */
.checkout {
  display: flex;
  padding: 20px;
  background-color: black;
  height: max-content;
  max-width: 1300px;
  margin: 0 auto;
}

.checkout__title {
  color: white;
  text-align: left;
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightblue;
  width: 50vw;
}

.checkout__ad {
  width: 100%;
  margin-bottom: 10px;
  height: 100px;
  border-bottom: 5px solid black;
  border-color: black;
}

.checkout__right {
  text-align: right;
  margin: 20px;
  flex: 2;
}

.checkout__left {
  text-align: right;
  margin: 20px;
  flex: 1;
}
.checkout__subtotal {
  margin-top: 20px;
}
.checkout__ad,
.checkout__subtotal,
.checkout__subtotal-sticky-wrapper {
  position: sticky;
  top: 80px; /* Элемент будет прилипать к верхней части вьюпорта */
  z-index: 100; /* Убедитесь, что элементы находятся поверх прокручиваемого содержимого */
}
/* Медиа-запросы для адаптации дизайна страницы оформления заказа под мобильные устройства */
@media only screen and (max-width: 600px) {
  .checkout {
    flex-direction: column; /* Стекируем блоки сверху вниз */
    padding: 10px; /* Уменьшаем внутренние отступы */
    height: auto; /* Высота автоматически подстраивается под содержимое */
  }

  .checkout__title {
    width: auto; /* Ширина автоматически подстраивается под содержимое */
    font-size: 16px; /* Уменьшаем размер шрифта для мобильных устройств */
  }

  .checkout__ad {
    position: sticky;
    height: auto; /* Высота баннера становится адаптивной */
    margin-bottom: 5px; /* Уменьшаем внешний отступ снизу */
    z-index: 100;
  }

  .checkout__right,
  .checkout__left {
    flex: none; /* Отменяем свойство flex */
    width: auto; /* Ширина автоматически подстраивается под содержимое */
    margin: 10px 0; /* Уменьшаем внешние отступы */
    text-align: left; /* Текст выравнивается по левому краю */
  }
  .checkout__right {
    text-align: center; /* Центрируем текст (если текст внутри) */
    flex-direction: column; /* Стекируем дочерние элементы вертикально */
    align-items: center; /* Центрируем дочерние элементы по горизонтали */
    /* другие стили... */
  }
  .checkout__subtotal {
    display: flex; /* Используем flexbox для выравнивания */
    justify-content: center; /* Центрируем содержимое по горизонтали */
    align-items: center; /* Центрируем содержимое по вертикали */
    text-align: center; /* Центрируем текст, если он там есть */
    margin: 0 auto; /* Дополнительное центрирование для блочных элементов */
    width: 100%; /* Oпционально: можно задать ширину 100%, если элемент ее не занимает полностью */
    /* другие стили, специфические для мобильной версии */
  }
}
